
<template>
	<div class="mbox">
		<el-form class="form" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" label-position="right">
			<el-form-item class="inputitem" prop="avatar" label="头像">
				<!-- <upload-header :src="ruleForm.avatar" @fileinfo="fileinfo"></upload-header> -->
				<img class="avatar" :src="ruleForm.avatar">
			</el-form-item>

			<el-form-item class="inputitem" prop="truename" :rules="rules.truename" label="姓名">
				<el-input placeholder="请输入真实的姓名" v-model="ruleForm.truename"></el-input>
			</el-form-item>

			<el-form-item class="inputitem" prop="company" :rules="rules.company" label="单位">
				<el-input placeholder="请输入单位名称" v-model="ruleForm.company"></el-input>
			</el-form-item>

			<el-form-item class="inputitem" prop="job" :rules="rules.job" label="职务">
				<el-input placeholder="请输入职务" v-model="ruleForm.job"></el-input>
			</el-form-item>

			<el-form-item class="inputitem" prop="email" :rules="rules.email" label="邮箱">
				<el-input placeholder="请输入邮箱" v-model="ruleForm.email"></el-input>
			</el-form-item>

			<el-form-item class="inputitem" prop="phone" label="手机号">
				<div>{{ ruleForm.phone }}</div>
			</el-form-item>

			<el-form-item label=" " class="inputitem">
				<el-button type="primary" class="btnsubmit" @click="submitForm('ruleForm')">保存信息</el-button>
			</el-form-item>

		</el-form>
	</div>
</template>

<script>
import uploadHeader from '@/components/upload/uploadHeader'
import { info, saveInfo } from '@/api/member'



export default {
	name: "WorkspaceJsonInfo",
	components: {
		uploadHeader
	},
	data() {
		return {

			ruleForm: {
				avatar: "",
				avatar_file_id: '', //上传文件返回的id
				truename: "",
				company: "",
				job: "",
				email: "",
				phone: ""
			},
			rules: {
				truename: [
					{ required: true, message: '姓名不能为空' },
					{ min: 2, max: 20, message: '姓名格式不正确' }
				],
				company: [
					{ required: true, message: '公司名称不能为空' },
					{ min: 2, max: 20, message: '公司名称格式不正确' }
				],
				job: [
					{ required: true, message: '职务不能为空' },
					{ min: 2, max: 20, message: '职务格式不正确' }
				]

			},
		};
	},

	mounted() {
		this.getMemberInfo()
	},

	methods: {
		//保存
		submitForm() {
			var that = this
			console.log('this.ruleForm', this.ruleForm)
			// this.ruleForm.id = 

			saveInfo(this.ruleForm).then(res => {


				that.$alert(res.message, '个人信息', {
					confirmButtonText: '确定',
					type: 'success',
					callback: action => {
					}
				})

				that.getMemberInfo()

			})
		},

		//文件信息
		fileinfo(file) {
			console.log('file', file)
			// alert(file)
			this.ruleForm.avatar_file_id = file.file_id
			this.ruleForm.avatar = file.url
		},

		//获取用户信息
		getMemberInfo() {
			info().then(res => {
				this.ruleForm = res.data
			})
		}

	},
};
</script>

<style lang="scss" scoped>
.mbox {
	width: 100%;
	min-height: calc(100vh - 180px);
	background: #fff;
}

.form {
	margin-left: 30px;

	.inputitem {
		width: 420px;
		margin-top: 20px;
		text-align: left;
	}

	.btnsubmit {
		width: 120px;
	}

	.avatar {
		height: 40px;
		width: 40px;
		border-radius: 50%;

	}
}
</style>