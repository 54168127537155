import dataapiRequest from '@/utils/dataapiRequest'

//获取七牛上传的token
export function qnToken(params){
  return dataapiRequest({
    url:'/upload/qiniu/getToken',
    method:'post',
    params
  })
} 




