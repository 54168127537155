<template>
	<div>
		<el-upload class="avatar-uploader" :show-file-list="false" :action="qiniuupload" list-type="text"
			:on-success="handleAvatarSuccess" :on-error="handleError" :before-upload="beforeAvatarUpload" :data="qiniuData">
			<img v-if="imgurl" :src="imgurl" class="avatar">
			<div v-else class="uploadbtn">
				<i class="el-icon-plus avatar-uploader-icon"></i>
			</div>
		</el-upload>

	</div>
</template>

<script>

import { qnToken } from '@/api/qiniu'
Array.prototype.contains = function (obj) {
	var index = this.length
	while (index--) {
		if (this[index] === obj) {
			return true
		}
	}
	return false
}

Array.prototype.indexOf = function (val) {
	for (var i = 0; i < this.length; i++) {
		if (this[i] == val) return i
	}
	return -1
}

Array.prototype.remove = function (val) {
	var index = this.indexOf(val)
	if (index > -1) {
		this.splice(index, 1)
	}
}


export default {
	props: {
		src: {
			type: String,
			default: ''
		}

	},
	data() {
		return {
			qiniuupload: 'https://up.qiniup.com', // 七牛云上传地址
			dialogImageUrl: '',
			dialogVisible: false,
			disabled: false,
			listtype: 'text',
			showTextButton: false, // true ：false  显示：隐藏 text 模式下的button
			filelist: {},
			qiniuData: {
				key: '',
				token: ''
			},
			// 七牛云返回储存图片的子域名
			// upload_qiniu_addr: "http://abc.clouddn.com/",
			imgurl: ''
		}
	},

	watch: {
		src(v) {
			this.imgurl = v
		}
	},

	mounted() {

	},
	methods: {

		getQiniuToken: async function (obj) {
			const _this = this
			const tokenParams = { ..._this.params, ...obj }
			const res = await qnToken(tokenParams)
			if (res.code) {
				_this.qiniuData.token = res.data.token
				_this.qiniuData.key = res.data.key
			} else {
				_this.$message({
					message: res.data.info,
					duration: 2000,
					type: 'warning'
				})
			}

		},

		beforeAvatarUpload: async function (file) {
			await this.getQiniuToken({ 'name': file.name })
			const isLt2M = file.size / 1024 / 1024 < 2 // 2M
			if (!isLt2M) {
				this.$message.error('图片大小不能超过 2MB!')
				return false
			}

		},

		handleAvatarSuccess: function (res, file) {
			this.imgurl = res.data.url
			
			this.filelist = {
				url: res.data.url,
				file_id: res.data.file_id,
				name: res.data.name
			}

			this.$emit('fileinfo', this.filelist)

		},

		handleError: function (res) {
			this.$message({
				message: '上传失败',
				duration: 2000,
				type: 'warning'
			})
		},

		handleRemove(file) {
			this.filelist.remove(file)
			this.$emit('fileinfo', this.filelist)
		},
		handlePictureCardPreview(file) {
			this.dialogImageUrl = file.url
			this.dialogVisible = true
		},
		handleDownload(file) {
			console.log(file)
		},
		updateSort() {

		}
	}
};
</script>

<style lang="scss" scoped>
.uploadbtn {
	height: 50px;
	width: 50px;
	border:solid 1px #fff;
	background:#f4f6f7;
	border-radius: 50%;
}

.avatar {
	height: 50px;
	width: 50px;
	border-radius: 50%;

}

.avatar-uploader .el-upload {
	border: 1px dashed #f1f0f0;
	border-radius: 50%;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}

.avatar-uploader .el-upload:hover {
	border-color: #409eff;
}

.avatar-uploader-icon {
	font-size: 28px;
	color: #409eff;
	width: 50px;
	height: 50px;
	line-height: 50px;
	text-align: center;
}

</style>
